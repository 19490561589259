import React from "react"
import { useNavigate } from "react-router-dom"
import { Typography, Checkbox } from "@mui/material"

import { useDeleteCreativeMutation } from "@/app/services/tags.js"
import { useAppDispatch, useAppSelector } from "@/app/hooks.js"

import { enqueueSnackbar } from "@/features/notifier/notifierSlice.js"
import { selectReadOnlyUser } from "@/features/auth/authSlice"
import { CreativeObjectType } from "@/model/creative.js"

import { MediaCardItem } from "@/components/MediaCardItem.js"
import { ConfirmModal } from "@/components/ConfirmModal.js"

import uncheckIcon from "@/assets/ion_checkbox-outline.png"
import checkedIcon from "@/assets/checked-icon-grid.png"

export type MediaCardProps = CreativeObjectType & {
  isSelected?: boolean
  onSelect?: (e: React.ChangeEvent<HTMLInputElement>) => void
  urlObj?: { asset: string; thumbnail: string }
  disableCheckbox?: boolean
  handleEdit?: () => void
  className?: string
  selectedCards: CreativeObjectType[]
  onCardDetailSelect: () => void
}

const styles = (hover: boolean) => ({
  container: {
    border: "1px solid #E4E4E4",
    borderRadius: "6px",
    position: "relative",
    width: "inherit",
    cursor: "pointer",
    transition: "box-shadow 225ms ease-in-out",
    boxShadow: hover ? "0px 0px 4px 0px rgba(0, 0, 0, 0.25)" : "",
    gridColumn: "auto / span 4",
    aspectRatio: "16/15",
  },
  checkbox: {
    position: "absolute",
    left: "0px",
  },
  progress: {
    color: "#FFF",
    animationDuration: "3s",
    "& .MuiCircularProgress-circle": {
      animationDuration: "3s",
    },
  },
  progressWrapper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate3d(-50%, -50%, 0)",
    height: "4rem",
  },
  nameTypography: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  },
  mediaWrapper: {
    background: "#C9C9C9",
    width: "100%",
    height: "70%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  },
})

export const MediaCard = ({
  creative_id,
  file_data: { upload_status, filename, file_type },
  created_at,
  tag_data,
  urlObj,
  isSelected,
  onSelect,
  disableCheckbox = false,
  onCardDetailSelect,
}: MediaCardProps) => {
  const navigate = useNavigate()
  const isReadOnly = useAppSelector(selectReadOnlyUser)
  const [hover, setHover] = React.useState(false)
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const tagKeys = Object.keys(tag_data).filter((d) => !d.includes("category"))
  const category = Object.keys(tag_data).filter((d) => d.includes("category"))

  const checkboxRef = React.useRef<HTMLInputElement>(null)

  const [deleteCreative, result] = useDeleteCreativeMutation()
  const dispatch = useAppDispatch()

  const handleIECGeneration = (id: string, name: string, type: string) => {
    if (!!urlObj) {
      const iecData = {
        name,
        type,
        url: urlObj.asset,
      }
      navigate("/iec", { state: iecData })
    }
  }

  const handleDeleteConfirm = async () => {
    try {
      const result = await deleteCreative({
        creativeId: creative_id,
        status: upload_status,
      }).unwrap()
      setIsModalOpen(false)
      dispatch(
        enqueueSnackbar({
          message: `Creative deleted successfully`,
          options: {
            key: "creative_delete_success",
            variant: "success",
          },
        }),
      )
    } catch (error) {
      setIsModalOpen(false)
      dispatch(
        enqueueSnackbar({
          message: `Creative deletion failed`,
          options: {
            key: "creative_delete_failed",
            variant: "error",
          },
        }),
      )
    }
  }

  const customStyles = styles(hover)
  return (
    <React.Fragment>
      <div
        className={`flex flex-col border border-solid border-neutral-gray3 rounded-md relative w-[inherit] cursor-pointer transition-shadow ${
          hover ? "shadow-[0px_0px_4px_0px_rgba(0,0,0,0.25)]" : ""
        } col-[auto_/_span_4] aspect-[16/15]`}
        onClick={onCardDetailSelect}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => {
          if (!disableCheckbox) {
            if (!isSelected && hover) {
              setHover(false)
            } else if (!isSelected && !hover) {
              setHover(false)
            }
          } else {
            setHover(false)
          }
        }}
      >
        <div className="w-full h-[70%] relative flex justify-center items-center overflow-hidden rounded-tl-lg rounded-tr-lg bg-[#C9C9C9]">
          <MediaCardItem
            fileType={file_type}
            uploadStatus={upload_status}
            alt={filename}
            src={
              !!urlObj && urlObj.thumbnail.length
                ? urlObj.thumbnail
                : urlObj?.asset
            }
            isThumbnail={!!urlObj && urlObj.thumbnail.length ? true : false}
          />
        </div>
        <div className="flex flex-col p-3 flex-grow gap-3 rounded-[0px_0px_8px_8px] min-h-[78px]">
          {!!tagKeys.length ? (
            <div className="flex items-center gap-2">
              {!!tag_data[tagKeys[0]].length ? (
                <div className="h-[24px] bg-[#E9E9E9] w-[auto] text-sm px-[10px] flex justify-center items-center rounded">
                  {tag_data[tagKeys[0]].slice(0, 15)}
                </div>
              ) : null}
              {tagKeys.length > 1 && !!tag_data[tagKeys[1]].length ? (
                <div className="h-[24px] bg-[#E9E9E9] w-[auto] text-sm px-[10px] flex justify-center items-center rounded">
                  {tag_data[tagKeys[1]].slice(0, 15)}
                </div>
              ) : null}
            </div>
          ) : (
            <div className="h-[24px] w-28 bg-[#E9E9E9] text-sm px-[10px] flex justify-center items-center rounded">
              {category}
            </div>
          )}

          <div className="flex items-center justify-between">
            <div className="flex items-center w-auto gap-2">
              <Typography variant="caption" sx={{ alignSelf: "flex-start" }}>
                {new Date(created_at).toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
              </Typography>
            </div>
            {isReadOnly ? null : (
              <button
                className="w-[89px] h-5 bg-[#FFF970] rounded-sm text-xs"
                onClick={() =>
                  handleIECGeneration(creative_id, filename, file_type)
                }
              >
                Convert to IEC
              </button>
            )}
          </div>
        </div>
        {upload_status === "failed" ||
        (!!Object.keys(tag_data).length && upload_status === "uploading") ||
        disableCheckbox ? null : (
          <Checkbox
            sx={customStyles.checkbox}
            inputRef={checkboxRef}
            onClick={(e) => e.stopPropagation()}
            onChange={onSelect}
            checked={isSelected}
            checkedIcon={<img src={checkedIcon} width="24px" />}
            icon={<img src={uncheckIcon} width="24px" />}
          />
        )}
      </div>
      <ConfirmModal
        open={isModalOpen}
        confirmText="Do you really want to delete the asset, this action cannot be reversed"
        handleClose={() => setIsModalOpen(false)}
        isLoading={result.isLoading}
        handleSubmit={handleDeleteConfirm}
      />
    </React.Fragment>
  )
}
